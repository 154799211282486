import React, { useState } from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withSnackbar } from "notistack";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AskImam = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [contactState, setContactState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const onNameChange = (event) => {
    setContactState({ ...contactState, name: event.target.value });
  };

  const onEmailChange = (event) => {
    setContactState({ ...contactState, email: event.target.value });
  };

  const onMsgChange = (event) => {
    setContactState({ ...contactState, message: event.target.value });
  };

  const submitEmail = (e) => {
    e.preventDefault();
    try {
      axios({
        method: "POST",
        url: "https://admin.darwenmosque.co.uk:8090/contact",
        data: contactState,
      }).then((response) => {
        console.log("imamResponse: ", response);
        props.enqueueSnackbar(response.data.message);
        setContactState({
          name: "",
          email: "",
          message: "",
        });
        setOpen(true);
        // if (response.data.status === "success") {
        //   alert("Message Sent.");
        //   this.resetForm();
        // } else if (response.data.status === "fail") {
        //   alert("Message failed to send.");
        // }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const resetForm = () => {
  //   setContactState({ name: "", email: "", subject: "", message: "" });
  // };

  return (
    <Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Request sent successfully
        </Alert>
      </Snackbar>
      <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <br />
        <p>‎السلام عليكم ورحمة الله وبركاته</p>
        <p>طَلَبُ الْعِلْمِ فَرِيضَةٌ عَلَى كُلِّ مُسْلِمٍ</p>
        <p>
          “Seeking knowledge is an obligation upon every Muslim.” (Source: Sunan
          Ibn Mājah 224)
        </p>
        <p>
          In order to fulfil our duty to Allah we have a duty to seek knowledge.
          Learning is a life long activity and the seeking of knowledge is
          considered a blessed activity in the eyes of Allah.
        </p>
        <p>
          Whether you are someone who is interested in the beautiful religion of
          Islam, or some striving to learn more about Deen, we are here to
          answer your questions.
        </p>
        <p>
          This service allows you to ask questions regarding all aspects of
          Islam for individuals of all backgrounds.
        </p>
        <br />
        <br />
        <Title>ASK A QUESTION</Title>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <form id="contact-form" onSubmit={submitEmail}>
                  <div className="form-group">
                    <div className="row">
                      <div
                        className="col-md-6"
                        style={{ paddingBottom: "20px" }}>
                        <TextField
                          placeholder="Name"
                          id="name"
                          type="text"
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          value={contactState.name}
                          onChange={onNameChange}
                        />
                      </div>
                      <div
                        className="col-md-6"
                        style={{ paddingBottom: "20px" }}>
                        <TextField
                          placeholder="Email"
                          id="email"
                          type="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          required
                          value={contactState.email}
                          onChange={onEmailChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group" style={{ paddingBottom: "20px" }}>
                    <TextField
                      placeholder="Message"
                      id="message"
                      multiline
                      rows={4}
                      label="Message"
                      variant="outlined"
                      fullWidth
                      required
                      value={contactState.message}
                      onChange={onMsgChange}
                    />
                  </div>
                  <Button variant="contained" type="submit" color="primary">
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default withSnackbar(AskImam);

const Container = styled.div`
  background-color: #fff;
  padding: 4% 4% 4% 4%;
`;

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;
